.component_header_icon {
    height: 75%;
    width: 200px;
}
.component_header_logo {
    height: 65%;
    width: 200px;
}
@media screen and (max-width: 800px) {
    .component_header_icon {
        height: 125px;
        width: 100px;
    }
    .component_header_logo {
        height: 112px;
        width: 112px;
    }
}